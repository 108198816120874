
import { defineComponent, onMounted, onUnmounted, PropType, shallowRef, toRefs, watch } from '@nuxtjs/composition-api'
import { createPopper, Instance } from '@popperjs/core/lib/popper-lite'
import type { Placement } from '@popperjs/core/lib/enums'

export default defineComponent({
  name: 'Popover',
  props: {
    placement: {
      type: String as PropType<Placement>,
      default: 'bottom'
    },
    modifiers: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    targetElement: {
      type: [Object] as PropType<HTMLElement>,
      validator: (el: HTMLElement) => el instanceof HTMLElement,
      default: null
    }
  },
  emits: ['onInit'],
  setup (props, { emit }) {
    const { targetElement } = toRefs(props)
    let dropdownPopper: Instance | undefined
    const rootElement = shallowRef<HTMLElement | undefined>()

    onMounted(() => {
      if (rootElement.value && targetElement.value) {
        dropdownPopper = createPopper(targetElement.value, rootElement.value, {
          placement: props.placement,
          modifiers: props.modifiers ?? []
        })
        emit('onInit', dropdownPopper)
        dropdownPopper.update()
      } else {
        throw new Error('Popover initialization failed: targetElement or rootElement is not defined')
      }
    })

    onUnmounted(() => {
      if (dropdownPopper) {
        dropdownPopper.destroy()
        dropdownPopper = undefined
      }
    })

    // Update a target element if it's changed
    watch(targetElement, (targetEl: HTMLElement) => {
      if (dropdownPopper) {
        dropdownPopper.state.elements.reference = targetEl
        dropdownPopper.update()
      }
    })

    return {
      rootElement
    }
  }

})
