
import { defineComponent, PropType, shallowRef, toRefs, watch } from '@nuxtjs/composition-api'
import { Instance } from '@popperjs/core/lib/popper-lite'
import { useListeners } from 'vue'
import MenuDropdown from '~/modules/mega-menu/components/MenuDropdown.vue'
import { safeComputed } from '~/modules/helpers'
import { MegaMenuItem } from '~/modules/mega-menu'
import Popover from '~/components/Popover.vue'

interface SelectedItemPair {
  el: HTMLElement, item: MegaMenuItem
}

export default defineComponent({
  name: 'DropdownCategoriesMenu',
  components: { Popover, MenuDropdown },
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    items: {
      type: Array as PropType<Array<MegaMenuItem>>,
      default: () => []
    },
    targetElement: {
      type: Object as PropType<HTMLElement>,
      validator: (el: HTMLElement) => el instanceof HTMLElement,
      default: null
    },
    preventOverflowElement: {
      type: [Object] as PropType<HTMLElement | undefined | null>,
      validator: (el: HTMLElement) => el instanceof HTMLElement,
      default: null
    }
  },
  emits: ['item:select'],
  setup (props, { emit }) {
    const listeners = useListeners()
    const { items: rootItems } = toRefs(props)
    const activeItems = shallowRef<Array<SelectedItemPair>>([])
    const popoverModifiers = safeComputed(() => [
      {
        name: 'offset',
        options: {
          offset: [128, 8]
        }
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: props.preventOverflowElement
        }
      }
    ])
    const subPopoverModifiers = safeComputed(() => [
      {
        name: 'offset',
        options: {
          offset: [-24, 12]
        }
      }
    ])

    function setActiveItem (item: SelectedItemPair, index: number) {
      const newActiveArray = activeItems.value.slice(0, index)
      newActiveArray[index] = item
      activeItems.value = newActiveArray
    }

    function handleItemHover (item: SelectedItemPair, index: number): void {
      setActiveItem(item, index)
    }

    function onItemSelect (item: MegaMenuItem): void {
      emit('item:select', item)
    }

    function setInstanceEventListener (instance: Instance, value: boolean): void {
      instance.setOptions(options => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: value }
        ]
      }))
    }

    /**
     * Reset a selected tree on new active items appeared
     */
    watch(rootItems, () => {
      activeItems.value = []
    })

    return {
      popoverModifiers,
      subPopoverModifiers,
      activeItems,
      listeners,
      handleItemHover,
      onItemSelect,
      setInstanceEventListener
    }
  }
})
