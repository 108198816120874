import { ComputedRef, useContext } from '@nuxtjs/composition-api'
import { AgnosticCategoryTree } from '@vue-storefront/core'
import { MegaMenuItem, MegaMenuTabs, UseMegaMenu } from '~/modules/mega-menu'
import { useMegaMenuStore } from '~/modules/mega-menu/store/useMegaMenuStore'
import { safeComputed } from '~/modules/helpers'
import { MEGA_MENU_LINKS } from '~/modules/mega-menu/resources/links'
import { MEGA_MENU_SERVICES } from '~/modules/mega-menu/resources/services'
import { getCategoryTree } from '~/modules/catalog/category/getters/categoryGetters'
import { prepareMenuItems } from '~/modules/mega-menu/helpers/prepareMenuItems'

export function useMegaMenu (): UseMegaMenu {
  const ctx = useContext()
  const megaMenuStore = useMegaMenuStore()
  const links = safeComputed(() => MEGA_MENU_LINKS)

  const categoryTree: ComputedRef<AgnosticCategoryTree> = safeComputed(() => getCategoryTree(megaMenuStore.categories?.[0]))
  const menuTree: ComputedRef<MegaMenuItem[]> = safeComputed(() => prepareMenuItems(categoryTree.value?.items ?? [], ctx))

  const tabs: ComputedRef<MegaMenuTabs> = safeComputed(() => {
    return {
      products: menuTree.value,
      services: MEGA_MENU_SERVICES
      // blog: []
    }
  })

  async function load (onlyFirstLevel: boolean = false): Promise<void> {
    await megaMenuStore.load(onlyFirstLevel)
  }

  return {
    categories: safeComputed(() => megaMenuStore.categories),
    loading: safeComputed(() => megaMenuStore.loading),
    links,
    tabs,
    categoryTree,
    menuTree,
    load
  }
}
