
import { SfLoader } from '@storefront-ui/vue'
import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { throttle } from 'lodash-es'
import { MegaMenuItem } from '~/modules/mega-menu'

export default defineComponent({
  name: 'MenuDropdown',
  components: {
    SfLoader
  },
  props: {
    items: {
      type: Array as PropType<MegaMenuItem[]>,
      default: null
    },
    activeItem: {
      type: Object as PropType<MegaMenuItem>,
      default: null
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    angledMovement: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    angledMovementDegree: {
      type: Number as PropType<number>,
      default: 60
    }
  },
  emits: ['hover:in', 'item:click'],
  setup (props, { emit }) {
    const HOVER_TIMEOUT = 100
    let lastX; let lastY = 0

    const movingAngle = ref(0)
    let hoverTimeout: ReturnType<typeof setTimeout>

    function isActive (item: MegaMenuItem): boolean {
      return props.activeItem === item
    }

    function hasChildren (item: MegaMenuItem): boolean {
      return !!item?.children.length
    }

    function onItemHover (item: MegaMenuItem, el: HTMLElement): void {
      clearHoverTimeout()
      if (movingAngle.value <= props.angledMovementDegree && props.angledMovement) {
        hoverTimeout = setTimeout(() => {
          emit('hover:in', { el, item })
        }, HOVER_TIMEOUT)
      } else {
        emit('hover:in', { el, item })
      }
    }

    function clearHoverTimeout ():void {
      clearTimeout(hoverTimeout)
    }

    const onMouseMove = throttle((event: MouseEvent) => {
      movingAngle.value = calculateAngleDegrees(
        Math.min(lastX, event.x),
        Math.min(lastY, event.y),
        Math.max(lastX, event.x),
        Math.max(lastY, event.y)
      )

      lastX = event.x
      lastY = event.y
    }, 50)

    /**
     * Calculates the angle in degrees between two points in a 2D Cartesian coordinate system.
     *
     * @param {number} x1 - The x-coordinate of the first point.
     * @param {number} y1 - The y-coordinate of the first point.
     * @param {number} x2 - The x-coordinate of the second point.
     * @param {number} y2 - The y-coordinate of the second point.
     * @return {number} The angle in degrees between the two points.
     */
    function calculateAngleDegrees (x1, y1, x2, y2):number {
      const dx = x2 - x1
      const dy = y2 - y1
      const radians = Math.atan2(dy, dx)
      return radians * (180 / Math.PI)
    }

    return {
      movingAngle,
      onMouseMove,
      isActive,
      hasChildren,
      clearHoverTimeout,
      onItemHover
    }
  }
})
