
import { ComputedRef, defineComponent, ref, shallowRef, useFetch, watch } from '@nuxtjs/composition-api'
import { entries } from 'lodash-es'
import MenuItem from './MenuItem.vue'
import MenuButton from './MenuButton.vue'
import { MegaMenuItem, MegaMenuTabsEntries } from '~/modules/mega-menu/interfaces'

import { safeComputed } from '~/modules/helpers/composition/safeComputed'
import { useMegaMenu } from '~/modules/mega-menu/composables/useMegaMenu'
import DropdownCategoriesMenu from '~/modules/mega-menu/components/DropdownCategoriesMenu.vue'

export default defineComponent({
  name: 'MegaMenu',
  components: {
    DropdownCategoriesMenu,
    MenuItem,
    MenuButton
  },
  setup () {
    const { tabs, links, load: loadMegaCategories } = useMegaMenu()

    const megaMenuItems = ref(null)

    const dropdownTargetElement = shallowRef<HTMLElement | undefined>()

    const activeTab = ref('products')

    const activeCategory = ref<MegaMenuItem | undefined>()
    const activeSubCategory = ref(null)
    const activeCategoryItems = safeComputed(() => activeCategory.value?.children ?? [])
    const activeSubItems = safeComputed(() => activeSubCategory.value?.children ?? [])
    const tabsArray: ComputedRef<MegaMenuTabsEntries> = safeComputed(() => entries(tabs.value))
    let resetTimeoutId: ReturnType<typeof setTimeout> | undefined
    let selectCategoryTimeout: ReturnType<typeof setTimeout> | undefined

    function clearResetTimeout ():void {
      if (resetTimeoutId) clearTimeout(resetTimeoutId)
      resetTimeoutId = undefined
    }

    function clearSelectCategoryTimeout ():void {
      if (selectCategoryTimeout) clearTimeout(selectCategoryTimeout)
      selectCategoryTimeout = undefined
    }

    useFetch(async () => {
      await loadMegaCategories(true)
    })

    function selectTab (tabId) {
      clearResetTimeout()
      activeTab.value = tabId
    }

    function handleHover ({ item, el } : {item : MegaMenuItem, el: HTMLElement}) {
      clearResetTimeout()
      clearSelectCategoryTimeout()
      if (activeTab.value !== 'products') return

      selectCategoryTimeout = setTimeout(() => {
        dropdownTargetElement.value = el
        activeCategory.value = item

        if (item.childrenCount && !item.children.length) {
          loadMegaCategories(false)
        }
      }, (activeCategory.value) ? 0 : 300)
    }

    function isActive (item) {
      return activeTab.value === 'products' && item?.id === activeCategory.value?.id
    }

    function resetMenuToDefault () {
      clearSelectCategoryTimeout()
      if (!activeCategory.value) {
        return
      }

      clearResetTimeout()
      resetTimeoutId = setTimeout(() => {
        activeTab.value = 'products'
        activeCategory.value = null
        activeSubCategory.value = null
      }, 700)
    }

    watch(activeTab, () => {
      activeCategory.value = null
    })

    watch(activeCategory, () => {
      activeSubCategory.value = null
    })

    watch(tabs, () => {
      if (activeCategory.value && tabs.value.produducts) {
        activeCategory.value = tabs.value.produducts.find(el => el.path === activeCategory.value.path)
      }
    })

    return {
      tabs,
      tabsArray,
      links,

      activeTab,
      activeCategory,
      activeSubCategory,
      activeCategoryItems,
      activeSubItems,

      megaMenuItems,
      dropdownTargetElement,

      resetMenuToDefault,
      selectTab,
      isActive,
      clearResetTimeout,
      handleHover
    }
  }
})
